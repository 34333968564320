import { defaultClient as apolloClient } from "../../main";
import {
  CREATE_NEW_PACKAGE,
  GET_PACKAGE_LISTING,
  GET_PACKAGE_DETAILS,
  UPDATE_PACKAGE,
  GET_THEMES_ICONS,
  GET_PACKAGE_SERVICES,
  MAKE_PACKAGE_PUBLISH,
  GET_PACKAGES_BY_COUNTRY,
  GET_PACKAGE_MEDIA_LIST,
  GET_AIRLINES_LIST,
  GET_PACKAGE_HOTELS_TYPE_LIST,
  CREATE_PACKAGE_HOTEL_DATA,
  GET_PACKAGE_HOTELS_LIST,
  GET_PACKAGE_SIGHTSEEING_LIST,
  CREATE_PACKAGE_SIGHTSEEING_DATA,
  UPDATE_AGENCY_TERMS_CONDITION,
  GET_AGENCY_TERMS_CONDITIONs,
  UPDATE_PACKAGE_FEATURE,
  GET_PKG_DESTINATIONS,
  FETCH_IMS_AIRLINES,
  UPLOAD_PACKAGE_FILE,
  REMOVE_PACKAGE_FILE,
  ON_CLEAR_GOA_STATIC_CACHE
} from "../../queries/packages.queries";

const state = {
  listLoading: false,
  themeLoading: false,
  updatePkgLoading: false,
  createHotelLoading: false,
  packages: [],
  airlinesList: [],
  themes: [],
  packageDetails: {},
  newPackageLoading: false,
  services: [],
  packageByCodeLoading: false,
  servicesLoading: false,
  publishingPackage: false,
  mediaLoading: false,
  mediaList: []
};
const mutations = {
  setlistLoading(state, payload) {
    state.listLoading = payload;
  },
  newPackageLoading(state, payload) {
    state.newPackageLoading = payload;
  },
  setCreateHotelLoading(state, payload) {
    state.createHotelLoading = payload;
  },
  packageByCodeLoading(state, payload) {
    state.packageByCodeLoading = payload;
  },
  activeService(state, payload) {
    state.services = [
      ...state.services.slice(0, payload.index),
      {
        ...state.services[payload.index],
        isactive: !state.services[payload.index].isactive
      },
      ...state.services.slice(payload.index + 1)
    ];
  },
  setAllPackages(state, payload) {
    state.packages = payload;
  },
  setPackages(state, payload) {
    state.packageDetails = payload;
  },
  setUpdatePkgLoading(state, payload) {
    state.updatePkgLoading = payload;
  },
  setThemeLoading(state, payload) {
    state.themeLoading = payload;
  },
  setThemes(state, payload) {
    state.themes = payload;
  },
  setPkgServices(state, payload) {
    state.services = payload;
  },
  setPkgServicesLoading(state, payload) {
    state.servicesLoading = payload;
  },
  publishingPackage(state, payload) {
    state.publishingPackage = payload;
  },
  setPkgMediaLoading(state, payload) {
    state.mediaLoading = payload;
  },
  setPackageMedia(state, payload) {
    state.mediaList = payload;
  },
  setAirlinesList(state, value) {
    state.airlinesList = value;
  }
};

/** @type {*} */
const actions = {
  getAllPackages: ({ commit }, payload) => {
    commit("setlistLoading", true);
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PACKAGE_LISTING,
          variables: { data: payload }
        })
        .then(({ data }) => {
          if (data.getAllPackages) {
            commit("setAllPackages", data.getAllPackages);
            resolve(data.getAllPackages);
          }
          commit("setlistLoading", false);
        })
        .catch((err) => {
          commit("setlistLoading", true);
          //console.log(err);
        });
    });
  },
  getAllPkgHotels: (__, data) => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PACKAGE_HOTELS_LIST,
          variables: { ...data }
        })
        .then(({ data }) => {
          resolve(data.getAllPkgHotels);
        })
        .catch((err) => {
          //console.log(err);
        });
    });
  },
  agencyTermsCondition: (__, agencyid) => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_AGENCY_TERMS_CONDITIONs,
          variables: { input: agencyid, type: "exist" }
        })
        .then(({ data }) => {
          resolve(data.affiliateExists);
        })
        .catch((err) => {
          //console.log(err);
        });
    });
  },
  getAllPkgSightseeing: (__, payload) => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PACKAGE_SIGHTSEEING_LIST,
          variables: payload ? { ...payload } : {}
        })
        .then(({ data }) => {
          resolve(data.getAllPkgSightseeing);
        })
        .catch((err) => {
          //console.log(err);
        });
    });
  },
  getAllPkgHotelsType: () => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PACKAGE_HOTELS_TYPE_LIST
        })
        .then(({ data }) => {
          resolve(data.getAllPkgHotelsType);
        })
        .catch((err) => {
          //console.log(err);
        });
    });
  },
  getPackageByCode: ({ commit }, code) => {
    commit("packageByCodeLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_PACKAGE_DETAILS,
          variables: { code }
        })
        .then(({ data }) => {
          resolve(data.getPackageByCode);
          commit("packageByCodeLoading", false);
          commit("setPackages", data.getPackageByCode);
        })
        .catch((err) => {
          commit("packageByCodeLoading", true);
          reject(err);
          //console.log(err);
        });
    });
  },
  getPackageServices: ({ commit }) => {
    commit("setPkgServicesLoading", true);
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PACKAGE_SERVICES
        })
        .then(({ data }) => {
          commit("setPkgServices", data.getPackageServices);
          resolve(data.getPackageServices);
          commit("setPkgServicesLoading", false);
        })
        .catch(() => {
          commit("setPkgServicesLoading", true);
        });
    });
  },
  getPackagesByCountry: ({ commit }, payload) => {
    commit("setlistLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_PACKAGES_BY_COUNTRY,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.getPackagesByCountry);
          commit("setlistLoading", false);
          commit("setAllPackages", data.getPackagesByCountry);
        })
        .catch((err) => {
          commit("setlistLoading", true);
          reject(err);
          //console.log(err);
        });
    });
  },
  /**
   * Action is Used to create a new package with basic details...
   *
   * @param {*} { commit } commit is used to call  mutations to change state
   * @param {*} payload
   * @returns
   */
  createPackage: ({ commit }, payload) => {
    commit("newPackageLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: CREATE_NEW_PACKAGE,
          variables: { data: { ...payload } }
        })
        .then(({ data }) => {
          resolve(data.createPackage);
          commit("newPackageLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("newPackageLoading", true);
        });
    });
  },
  updateAgencyTerms: ({ commit }, payload) => {
    commit("newPackageLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: UPDATE_AGENCY_TERMS_CONDITION,
          variables: { input: { ...payload } }
        })
        .then(({ data }) => {
          resolve(data.updateInfoAndContact);
          commit("newPackageLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("newPackageLoading", true);
        });
    });
  },
  createPackageHotel: ({ commit }, payload) => {
    commit("setCreateHotelLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: CREATE_PACKAGE_HOTEL_DATA,
          variables: { data: { ...payload } }
        })
        .then(({ data }) => {
          resolve(data.createNewHotel);
          commit("setCreateHotelLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("setCreateHotelLoading", true);
        });
    });
  },
  createPackageSightseeing: ({ commit }, payload) => {
    commit("setCreateHotelLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: CREATE_PACKAGE_SIGHTSEEING_DATA,
          variables: { data: { ...payload } }
        })
        .then(({ data }) => {
          resolve(data.createNewSightseeing);
          commit("setCreateHotelLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("setCreateHotelLoading", true);
        });
    });
  },
  updatePackage: ({ commit }, payload) => {
    commit("setUpdatePkgLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: UPDATE_PACKAGE,
          variables: { ...payload }
        })
        .then(({ data }) => {
          resolve(data.updatePackage);
          commit("setUpdatePkgLoading", false);
        })
        .catch((err) => {
          reject(err);
          commit("setUpdatePkgLoading", true);
        });
    });
  },
  getThemeIcons: ({ commit }) => {
    commit("setThemeLoading", true);
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_THEMES_ICONS,
          variables: { type: "theme" }
        })
        .then(({ data }) => {
          commit("setThemes", data.getIcons);
          resolve(data.getIcons);
          commit("setThemeLoading", false);
        })
        .catch(() => {
          commit("setThemeLoading", true);
        });
    });
  },
  makePackagePublishRequest: ({ commit }, payload) => {
    commit("publishingPackage", false);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: MAKE_PACKAGE_PUBLISH,
          variables: { input: payload }
        })
        .then(({ data }) => {
          resolve(data.makePackagePublish);
          commit("publishingPackage", false);
        })
        .catch((err) => {
          reject(err);
          commit("publishingPackage", true);
        });
    });
  },
  getPackageMediaList: ({ commit }, payload) => {
    commit("setPkgMediaLoading", true);
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PACKAGE_MEDIA_LIST,
          variables: { data: payload }
        })
        .then(({ data }) => {
          commit("setPackageMedia", data.getMedia);
          resolve(data.getMedia);
          commit("setPkgMediaLoading", false);
        })
        .catch((err) => {
          //console.error(err);
          commit("setPkgMediaLoading", true);
        });
    });
  },
  getPackageDestinations: () => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_PKG_DESTINATIONS
        })
        .then(({ data }) => {
          resolve(data.getPkgDestinations);
        })
        .catch((err) => {
          //console.error(err);
        });
    });
  },
  getAirlinesList: ({ commit }) => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: GET_AIRLINES_LIST
        })
        .then(({ data }) => {
          commit("setAirlinesList", data.getAirlines);
          resolve(data.getAirlines);
        })
        .catch((err) => {
          //console.error(err);
        });
    });
  },
  /**
   * @description
   * @author Saurabh Jangra
   * @date 11 Jan 2024 | 04:49PM
   * @returns {*}
   */
  fetchImsAirlines: () => {
    return new Promise((resolve) => {
      apolloClient
        .query({
          query: FETCH_IMS_AIRLINES
        })
        .then(({ data }) => {
          resolve(data.fetchImsAirlines);
        });
    });
  },
  updatePackageFeature: (_, payload) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: UPDATE_PACKAGE_FEATURE,
          variables: payload
        })
        .then(({ data }) => {
          resolve(data.updateFeaturePackage);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * @description
   * @author Saurabh Jangra
   * @date 20 Jul 2024 | 04:12PM
   * @param {*} __
   * @param {*} payload
   * @returns {*}
   */
  uploadPackageFile(__, payload) {
    return new Promise(async (resolve) => {
      const _result = await apolloClient.mutate({ mutation: UPLOAD_PACKAGE_FILE, variables: { payload } }).then(({ data }) => data.uploadPackageFile);
      resolve(_result);
    });
  },
  /**
   * @description
   * @author Saurabh Jangra
   * @date 22 Jul 2024 | 12:59PM
   * @param {*} __
   * @param {*} payload
   * @returns {*}
   */
  removePackageFile(__, payload) {
    return new Promise(async (resolve) => {
      const _result = await apolloClient.mutate({ mutation: REMOVE_PACKAGE_FILE, variables: { payload } }).then(({ data }) => data.removePackageFile);
      resolve(_result);
    });
  },
  onClearGoaCache(__, ___) {
    return new Promise(async (resolve, reject) => {
      try {
        const _result = await apolloClient
          .mutate({ mutation: ON_CLEAR_GOA_STATIC_CACHE })
          .then(({ data }) => data.pkg_goa_clear_cache)
          .catch((err) => reject(err));
        resolve(_result);
      } catch (error) {
        reject(error);
      }
    });
  }
};

const getters = {
  packageServices: (state) => state.services,
  newPackageLoading: (state) => state.newPackageLoading,
  packagesList: (state) => state.packages,
  packagesLoading: (state) => state.listLoading,
  packageDetails: (state) => state.packageDetails,
  pkgThemes: (state) => state.themes,
  packageByCodeLoading: (state) => state.packageByCodeLoading,
  mediaLoading: (state) => state.mediaLoading,
  mediaList: (state) => state.mediaList,
  airlinesList: (state) => state.airlinesList,
  createHotelLoading: (state) => state.createHotelLoading
};

export default {
  state,
  mutations,
  actions,
  getters
};
